import { ColorToken } from "../types";

export const colors: {
  dm: Record<ColorToken, string>;
  lm: Record<ColorToken, string>;
} = {
  dm: {
    "ui-01": "#181b1a",
    "ui-02": "#232525",
    "ui-03": "#353838",
    "ui-04": "#505454",
    "ui-05": "#838686",
    "ui-bg": "#0f0f10",
    "text-01": "#fff",
    "text-02": "#cacad3",
    "text-03": "#9e9e9e",
    "text-04": "#ffffff",
    "text-05": "#000000",
    "primary-01": "#3a3ac1",
    "primary-02": "#4545c1",
    "primary-03": "#2a2aab",
    "success-01": "#1ba886",
    "success-02": "#0CAF7E",
    "success-03": "#0C765D",
    "focus-01": "#ff9e1d",
    "danger-01": "#D95373",
    "danger-02": "#FF496A",
    "danger-03": "#AE103F",
  },
  lm: {
    "ui-01": "#f4f6f6",
    "ui-02": "#e4e4e4",
    "ui-03": "#707574",
    "ui-04": "#3a403f",
    "ui-05": "#272D2C",
    "ui-bg": "#ffffff",
    "text-01": "#000000",
    "text-02": "#2c3735",
    "text-03": "#6e6e6e",
    "text-04": "#ffffff",
    "text-05": "#000000",
    "primary-01": "#3a3ac1",
    "primary-02": "#4545c1",
    "primary-03": "#2a2aab",
    "success-01": "#1ba886",
    "success-02": "#0CAF7E",
    "success-03": "#0C765D",
    "focus-01": "#6a38ff",
    "danger-01": "#a81b3d",
    "danger-02": "#FF496A",
    "danger-03": "#AE103F",
  },
};
